<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="下架" :value="2"></el-option>
          <el-option label="上架中" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addPackage">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 2">下架</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 1"
            >上架中</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="updateInfo(row)"
            >编辑</el-button
          >
            <el-button v-if="row.status == 2" type="success" size="small" @click="updateStatus(row,1)"
            >上架</el-button
          >
            <el-button v-if="row.status == 1"  type="warning" size="small" @click="updateStatus(row,2)"
            >下架</el-button
          >
           <el-button type="success" size="small" @click="openGrant(row)">发放券包</el-button>
            <el-button size="small" type="primary" @click="shareCouponPage(row)"
          >二维码</el-button
        >
        </div>
      </template>
    </auto-table>

 <el-dialog
      title="二维码"
      width="40%"
      :visible.sync="qrdialogVisible"
    >
      <div class="flex jc-c">
        <img :src="codeimg" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrdialogVisible = false">确定</el-button>
      </span>
    </el-dialog>

    <grantPackage ref="grant"></grantPackage>
  </div>
</template>

<script>
import grantPackage from './grantPackage.vue';
export default {
    components:{
        grantPackage
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "价值金额", value: "cash" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 1,
      qrdialogVisible:false,
      codeimg:''
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    shareCouponPage(row){
      this.codeimg = ''
        this.qrdialogVisible = true;
     let data = {
        scene: `pid=${row.id}`,
        page: 'pages/activity/couponPackage/userRecive',
      };
      this.$axios({
        url: "/user/user/generateQRCodeSportPic",
        data,
        method: "post",
      }).then((res) => {
         this.codeimg = res.data.data.img;
      });
    },
    openGrant(row){
        this.$refs.grant.open(row)
    },
    addPackage() {
      this.$router.push({ name: "editCouponPackage" });
    },
    updateInfo(row) {
      this.$router.push({ name: "editCouponPackage", query: { id: row.id } });
    },
    updateStatus(row,status){
       
        this.$post('/user/couponPackage/update',{id:row.id,status}).then(res=>{
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/couponPackage/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>